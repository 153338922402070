import { Button, Divider, FormControl, FormErrorMessage, GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import StoreApi from 'src/api/store'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { FormCard } from 'src/components/Layout/FormCard'
import { SURVEY_ANSWER_TYPE_FOR_SELECT, SURVEY_TRIGGER_FOR_SELECT } from 'src/constants'
import { useAppContext } from 'src/contexts/AppContext'
import SurveyAnswerTypeEnum from 'src/enum/SurveyAnswerTypeEnum'
import { Types } from 'src/reducers/AppReducer'
import { IAddress } from 'src/types/Address'
import { Formik } from 'src/types/Formik'
import { PaginationResponse } from 'src/types/Pagination'
import { ISurvey } from 'src/types/Survey'
import { IStore } from 'src/types/User'

const SurveyForm: React.FC<{ formik: Formik<ISurvey> }> = ({ formik }) => {
  const { dispatch } = useAppContext()
  const { errors, values, handleBlur, handleChange } = formik
  const [grocerList, setGrocerList] = React.useState<IStore[]>([])

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get({ limit: 1000, skip: 0 })
      .then((res: PaginationResponse<IStore>) => {
        setGrocerList(res.data ?? [])
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch])

  const handleAddOption = () => {
    formik.setValues({
      ...values,
      options: [...values.options, { label: '', value: '' }],
    })
  }

  return (
    <FormCard title="Survey Details">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input error={errors.title} label="Title" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
        </GridItem>
        <GridItem>
          <Input error={errors.question} label="Question" name="question" onBlur={handleBlur} onChange={handleChange} value={values.question} />
        </GridItem>
        <GridItem>
          <MultiSelect
            label="Stores"
            name="storeIdList"
            error={errors.storeIdList}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.storeIdList || []}
            options={grocerList.map((grocer: IStore) => ({
              value: grocer._id,
              label: `${grocer.businessName} - ${(grocer?.address as IAddress)?.suburb}`,
            }))}
          />
        </GridItem>
        <GridItem>
          <MultiSelect
            label="Trigger"
            name="trigger"
            isMulti={false}
            error={errors.trigger}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.trigger || []}
            options={SURVEY_TRIGGER_FOR_SELECT}
          />
        </GridItem>
        <GridItem>
          <MultiSelect
            label="Answer type"
            name="answerType"
            isMulti={false}
            error={errors.answerType}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.answerType || []}
            options={SURVEY_ANSWER_TYPE_FOR_SELECT}
          />
        </GridItem>
        {
          values.answerType !== SurveyAnswerTypeEnum.TEXT && (
            <>
              <GridItem display="flex" alignItems="end">
                <Button onClick={handleAddOption}>Add Option</Button>
              </GridItem>
              <GridItem colSpan={2}>
                <Divider />
              </GridItem>
            </>
          )
        }
        {
          formik.errors.options && (
            <GridItem colSpan={2}>
              <FormControl isInvalid={true}>
                <FormErrorMessage>{formik.errors.options as string}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )
        }

        {values.options.map((option, index) => (
          <React.Fragment key={index}>
            <GridItem>
              <Input
                label={`Option ${index + 1} Label`}
                name={`options[${index}].label`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={option.label}
              />
            </GridItem>
            <GridItem>
              <Input
                label={`Option ${index + 1} Value`}
                name={`options[${index}].value`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={option.value}
              />
            </GridItem>
          </React.Fragment>
        ))}

      </SimpleGrid>
    </FormCard>
  )
}

export default SurveyForm
