import { Flex, GridItem, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import { dismissAllAlerts } from 'src/components/Alert'
import { Input } from 'src/components/Form/Input'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { AuthMfaInitialValue, IAuthResponse } from 'src/types/Auth'
import validationSchema from './validationSchema'

const Mfa = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = React.useContext(AppContext)

  const params = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const qrCode = params.get('code') ? decodeURIComponent(params.get('code')) : undefined
  const email = decodeURIComponent(params.get('email'))

  const onSubmit = React.useCallback((values: any) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    dismissAllAlerts()
    AuthApi.validateMfa({ email, passcode: values.passcode })
      .then((data: IAuthResponse) => {
        if (data.user) {
          dispatch({
            type: Types.Authenticate,
            payload: {
              user: data.user,
              isAuthenticated: true,
              token: data.token,
              refreshToken: data.refreshToken,
            },
          })

          navigate('/app/dashboard', { replace: true })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  },
    [navigate, dispatch, email]
  )

  const { errors, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: AuthMfaInitialValue,
    onSubmit,
    validationSchema,
  })

  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e)

    if (e.target.value.length === 6) onSubmit({ passcode: e.target.value })
  }, [handleChange, onSubmit])

  return (
    <Page title="Generate your MFA" w="100%">
      <Flex direction="column" h="100%" w="100%" justify="center" align="center" gap="4">
        {qrCode && (
          <>
            <VStack spacing="0">
              <Text variant="text-20-bold">You must enable MFA before continuing.</Text>
              <Text variant="text-16-reg" align="center">
                Please, scan the following QRCODE with your Authenticator App.
              </Text>
            </VStack>
            <Image src={qrCode} w="20rem" h="20rem" />
          </>
        )}
        <form onSubmit={handleSubmit}>
          <SimpleGrid spacing="4" w="100%" mb="3">
            <GridItem>
              <Text variant="text-16-bold">Enter the code generated by your Authenticator App</Text>
            </GridItem>
            <GridItem>
              <Input error={errors.passcode} label="Code" name="passcode" onBlur={handleBlur} onChange={onChange} value={values.passcode} autoFocus />
            </GridItem>
          </SimpleGrid>
        </form>
      </Flex>
    </Page>
  )
}

export default Mfa
