import { ChakraSpan } from '../Form/ChakraSpan'
import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton'

interface ToolbarButtonProps extends PrimaryButtonProps {
  icon?: React.ReactNode
  label: string
}

export function ToolbarButton({ icon, label, ...args }: ToolbarButtonProps) {
  return (
    <PrimaryButton variant="outline" _hover={{ bgColor: 'gray.100' }} {...args}>
      <ChakraSpan mr="2" fontSize="xl">{icon}</ChakraSpan>
      {label}
    </PrimaryButton>
  )
}
