import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ImageSuggestionApi from 'src/api/imageSuggestion'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IImageSuggestion, imageSuggestionInitialValue } from 'src/types/ImageSuggestion'
import validationSchema from 'src/views/ImageSuggestion/validationSchema'
import ImageSuggestionForm from './ImageSuggestionForm'

const CreateImageSuggestion: React.FC = () => {
  const pageName: string = 'Create Image Suggestion'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IImageSuggestion) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ImageSuggestionApi.create({ pictureFile: values.pictureFile })
      .then((result) => {
        if (result?.success) {
          AlertSuccess('Image Suggestion saved successfully!')
          navigate('/app/image-suggestions')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: imageSuggestionInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack
          buttons={
            <PrimaryButton variant="solid" type="submit">
              Save
            </PrimaryButton>
          }
        />
        <ImageSuggestionForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateImageSuggestion
