enum SurveyTriggerEnum {
  // AFTER_BUYING_FROM = 'AFTER_BUYING_FROM',
  FIRST_ORDER_FROM_STORE = 'FIRST_ORDER_FROM_STORE',
  NEVER_ORDERED_FROM = 'NEVER_ORDERED_FROM',
  // FIRST_ORDER = 'FIRST_ORDER',
  NEXT_TIME_OPENING_APP = 'NEXT_TIME_OPENING_APP',
  // NOT_ANSWERED = 'NOT_ANSWERED'
}

export default SurveyTriggerEnum
