import { Badge, Button, Flex, GridItem, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import { AlertError } from '../Alert'
import CopyLinkButton from '../CopyLinkButton'
import { Input } from '../Form/Input'
import AddStatementEmailModal from '../Overlay/AddStatementEmailModal'
import { FormCard } from './FormCard'

interface StoreSettingsCardProps {
  formik: Partial<Formik<IStore>>
  isOnTrialSubscription: boolean
}

export function StoreSettingsCard({ formik, isOnTrialSubscription }: StoreSettingsCardProps) {
  const { setValues, errors, values, handleBlur, handleChange } = formik
  const statementModalDisclosure = useDisclosure()

  const handleRemoveStatementEmail = (statementEmail: string) => {
    setValues((prev) => ({
      ...prev,
      statementEmails: prev.statementEmails?.filter((email) => email !== statementEmail),
    }))
  }

  const handleAddStatementEmail = (email: string) => {
    if (values.statementEmails?.includes(email)) {
      AlertError('Email already added')
      return
    }
    setValues((prev) => ({
      ...prev,
      statementEmails: [...(prev.statementEmails || []), email],
    }))
    statementModalDisclosure.onClose()
  }

  return (
    <FormCard title="Store Settings">
      {isOnTrialSubscription && (
        <Badge colorScheme="red" my="2">
          This store is on the commission trial (0% commission).
        </Badge>
      )}
      <SimpleGrid columns={[1, 1, 2]} spacing={2}>
        <GridItem>
          <Input
            error={errors.commissionPercentage}
            type="number"
            label="Commission Percentage"
            name="commissionPercentage"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.commissionPercentage}
          />
        </GridItem>
        <GridItem>
          <Input
            type="date"
            error={errors.zeroCommissionTrialEndsAt}
            label="Trial ends at"
            name="zeroCommissionTrialEndsAt"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.zeroCommissionTrialEndsAt as string | null}
          />
        </GridItem>
        <GridItem>
          <Text fontWeight="semibold">Statement Emails</Text>
          <Flex direction="column" gap="2" mb="4">
            {
              values.statementEmails?.map((email) => (
                <Flex key={email} alignItems="center" justifyItems="space-between" gap='4'>
                  <Text>
                    {email}
                  </Text>

                  <Button type="button" size="xs" variant="outline" colorScheme="red" onClick={() => handleRemoveStatementEmail(email)}>
                    Remove
                  </Button>
                </Flex>
              ))
            }
          </Flex>
          <Button size="sm" colorScheme="primary" variant="outline" w="full" type="button" onClick={statementModalDisclosure.onOpen}>
            Add Email
          </Button>
          {/* <Input
            error={errors.statementEmail}
            label="Statement Email"
            name="statementEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.statementEmail}
          /> */}
        </GridItem>
        <GridItem colSpan={[1, 1, 2]} display="flex" alignItems="flex-end" >
          <CopyLinkButton shortUrl={values.shortUrl} />
        </GridItem>

        <AddStatementEmailModal
          handleClose={statementModalDisclosure.onClose}
          handleConfirm={handleAddStatementEmail}
          open={statementModalDisclosure.isOpen}
          title="Add Statement Email"
        />
      </SimpleGrid>
    </FormCard>
  )
}
