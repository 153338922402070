export interface IBusinessFaq {
  id?: string
  title: string
  description: string
  isForSignup: boolean
  signupStep?: number

  [key: string]: any
}

export const businessFaqInitialValue: IBusinessFaq = {
  title: '',
  description: '',
  isForSignup: false
}
