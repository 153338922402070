import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IImageSuggestion } from 'src/types/ImageSuggestion'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type IImageSuggestionApi = {
  get(params: PaginationData<IImageSuggestion>): Promise<PaginationResponse<IImageSuggestion>>
  getById(id: string): Promise<IImageSuggestion>
  create(imageSuggestion: Partial<IImageSuggestion>): Promise<{ success: boolean }>
  destroyMany(idList: string[]): Promise<{ success: boolean }>
  changeStatus(values: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
}

const ImageSuggestionApi = (): IImageSuggestionApi => {
  const imageSuggestionApi = '/admin/image-suggestion'

  const get = (params: PaginationData<IImageSuggestion>): Promise<PaginationResponse<IImageSuggestion>> =>
    api.get(`${imageSuggestionApi}${generateParamsFromPagination(params)}`)
  const create = (imageSuggestion: IImageSuggestion): Promise<{ success: boolean }> => api.post(imageSuggestionApi, generateMultipartBodyForm(imageSuggestion))
  const getById = (id: string): Promise<IImageSuggestion> => api.get(`${imageSuggestionApi}/${id}`)
  const destroyMany = (idList: string[]): Promise<{ success: boolean }> => api.post(`${imageSuggestionApi}/delete`, { idList })
  const changeStatus = (values: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${imageSuggestionApi}/toggle`, { ...values, type: activate ? 'activate' : 'deactivate' })

  return {
    get,
    getById,
    create,
    destroyMany,
    changeStatus
  }
}

export default ImageSuggestionApi()
