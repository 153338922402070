import { TableColumn } from 'src/components/CustomDataTable'
import { IAddress } from 'src/types/Address'
import IReview from 'src/types/Review'

const tableColumns: TableColumn<IReview>[] = [
  {
    label: 'Store',
    name: 'business',
    options: {
      customRender: (review) => (`${review?.["business.businessName"]} - ${review?.["business.address.suburb"] as IAddress}`)
    },
  },
  {
    label: 'Customer',
    name: 'author',
    options: {
      customRender: (review) => (`${review?.["author.firstName"]} ${review?.["author.lastName"]}`),
    },
  },
  {
    label: 'Rate',
    name: 'rate',
  },
  {
    label: 'Order',
    name: 'orderCode',
  },
  {
    label: 'Comments',
    name: 'comments',
  },
]

export default tableColumns
