import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageSuggestionApi from 'src/api/imageSuggestion'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { IImageSuggestion } from 'src/types/ImageSuggestion'
import { PaginationResponse } from 'src/types/Pagination'
import tableColumns from './tableColumns'

const ImageSuggestion = () => {
  const navigate = useNavigate()
  const [imageSuggestionList, setImageSuggestionList] = useState<PaginationResponse<IImageSuggestion>>({ data: [], count: 0 })
  const { dispatch } = useContext(AppContext)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })


  useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ImageSuggestionApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IImageSuggestion>) => {
        setImageSuggestionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setImageSuggestionList, tableState])

  const handleDelete = (idList: string[]) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ImageSuggestionApi.destroyMany(idList)
      .then(() => {
        setImageSuggestionList((prev: PaginationResponse<IImageSuggestion>) => {
          return {
            data: prev.data.filter((item: IImageSuggestion) => !idList.includes(item.id || item._id)),
            count: prev.count - idList.length,
          }
        }
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ImageSuggestionApi.changeStatus({ idList }, activate)
      .then(() => {
        setImageSuggestionList((prev: PaginationResponse<IImageSuggestion>) => {
          return {
            ...prev,
            data: prev.data.map((imageSuggestion: IImageSuggestion) => {
              if (idList.indexOf(imageSuggestion.id) > -1) imageSuggestion.isActive = activate
              return imageSuggestion
            })
          }
        }
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={imageSuggestionList.data} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} extraButtons={
      <PrimaryButton colorScheme='red' onClick={() => handleDelete(selectedRows.map(row => imageSuggestionList.data[row].id || imageSuggestionList.data[row]._id))}>Delete</PrimaryButton>
    } />
  }

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const dataTableProps: CustomDataTableProps<IImageSuggestion> = {
    tableColumns,
    data: imageSuggestionList,
    showCheckbox: true,
    onTableChange,
    tableState,
    customToolbar,
    serverSidePagination: true,
  }

  return (
    <Page title="Image Suggestions">
      <Toolbar title="Image Suggestions" buttons={<PrimaryButton onClick={handleAdd}>Add Image</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable {...dataTableProps} />
      </Box>
    </Page>
  )
}

export default ImageSuggestion
