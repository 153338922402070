import { TableColumn } from 'src/components/CustomDataTable'
import { formatDateToString } from 'src/helpers/stringHelper'
import IEmailLog from 'src/types/EmailLog'

const customerEmailsTableColumns: TableColumn<IEmailLog>[] = [
  {
    label: 'Subject',
    name: 'subject',
  },
  {
    label: 'Sent at',
    name: 'sendAt',
    options: {
      customRender: (emailLog) => (emailLog ? formatDateToString(emailLog.sendAt, { month: '2-digit', hour: '2-digit', minute: '2-digit' }) : '-'),
    },
  },
  {
    label: 'Error',
    name: 'error',
    options: {
      customRender: (emailLog) => emailLog.error ?? '-',
    }
  },
]

export default customerEmailsTableColumns
