import {
  GridItem,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import IReview from 'src/types/Review'
import { PrimaryButton } from '../Button/PrimaryButton'
import { DataTextField } from '../Form/DataTextField'
import ConfirmationModal from './ConfirmationModal'

interface ReviewDetailsModalProps {
  review: IReview
  onClose: () => void
  isOpen: boolean
  onDelete?: (reviewId: string) => void
}

export function ReviewDetailsModal({ review, onClose, onDelete, isOpen }: ReviewDetailsModalProps) {
  const confirmationDisclosure = useDisclosure()

  const grocer = review?.business || review?.grocer

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Review from {review?.author?.firstName} {review?.author?.lastName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={[1, 1, 2]} spacing={2}>
            <GridItem>
              <DataTextField title="Customer Name">
                <Text>{`${review?.author?.firstName} ${review?.author?.lastName}` || '-'}</Text>
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Business">
                <Text>{grocer?.businessName}</Text>
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Rating">
                <Text>{review?.rate || '-'}</Text>
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Order">
                <Text>{review?.orderCode || '-'}</Text>
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Customer review">
                <Text>{review?.comments || '-'}</Text>
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Business report reason">
                <Text>{review?.reportReason || '-'}</Text>
              </DataTextField>
            </GridItem>
            <GridItem colSpan={2}>
              <Text variant="text-18-semi">Pictures</Text>
              {review?.photos && review?.photos.length > 0
                ? review.photos.map((p) => (
                  <Link href={p} target="_blank" rel="noreferrer">
                    <Image src={p} alt="review" />
                  </Link>
                ))
                : '-'}
            </GridItem>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          {
            onDelete && (
              <PrimaryButton colorScheme='red' onClick={confirmationDisclosure.onOpen} marginRight="10px">Delete</PrimaryButton>
            )
          }
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </ModalFooter>
      </ModalContent>
      <ConfirmationModal handleClose={confirmationDisclosure.onClose} open={confirmationDisclosure.isOpen} handleConfirm={() => onDelete(review.id || '')} />
    </Modal>
  )
}
