import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Checkbox } from 'src/components/Form/Checkbox'
import { Input } from 'src/components/Form/Input'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { IBusinessFaq } from 'src/types/BusinessFaq'
import { Formik } from 'src/types/Formik'

const BusinessFaqForm: React.FC<{ formik: Formik<IBusinessFaq> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Business FAQ Details">
      <SimpleGrid columns={1} spacing={4}>
        <GridItem>
          <Input error={errors.title} label="Title" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
        </GridItem>
        <GridItem>
          <Textarea
            error={errors.description}
            label="Description"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            rows={5}
          />
        </GridItem>
        <GridItem>
          <Checkbox
            error={errors.isForSignup}
            label="Is for Signup?"
            name="isForSignup"
            onBlur={handleBlur}
            onChange={handleChange}
            defaultChecked={values.isForSignup}
            checked={values.isForSignup}
            isChecked={values.isForSignup}
          />
        </GridItem>
        {
          values.isForSignup && (
            <GridItem>
              <Input
                type="number"
                error={errors.signupStep}
                label="Signup Step"
                name="signupStep"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.signupStep}
              />
            </GridItem>
          )
        }
      </SimpleGrid>
    </FormCard>
  )
}

export default BusinessFaqForm
