import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import ImageUpload from 'src/components/Form/ImageUpload'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IImageSuggestion } from 'src/types/ImageSuggestion'
import { IUploadFile } from 'src/types/UploadFile'

const ImageSuggestionForm: React.FC<{ formik: Formik<IImageSuggestion> }> = ({ formik }) => {
  const { values, setFieldValue } = formik

  const onUpload = (_name: string, _uploadFile: IUploadFile, _preview: string, file: File) => {
    setFieldValue('pictureFile', file)
  }

  return (
    <FormCard title="Image Suggestion">
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        <GridItem>
          <ImageUpload onUpload={onUpload} name="pictureFile" showPreview={true} currentPicture={values.url} />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default ImageSuggestionForm
