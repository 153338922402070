import { Card, Center, Divider, GridItem, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { ChakraSpan } from './Form/ChakraSpan'
import { MultiSelect, MultiSelectOptionProps } from './Form/MultiSelect'

const colors = [
  ['#ffa726', '#fb8c00', '255,167,38'],
  ['#ef5350', '#e53935', '239,83,80'],
  ['#66bb6a', '#43a047', '102,187,106'],
  ['#26c6da', '#00acc1', '38,198,218'],
  ['#ec407a', '#d81b60', '236,64,122'],
  ['#a599fd', '#736bb1', '165,153,253'],
  ['#ff6659', '#cc5147', '255,102,89'],
  ['#466bff', '#3855cc', '70,107,255'],
  ['#b66e0e', '#91580b', '182,110,14'],
]

interface DashboardCardProps {
  icon: any
  value: string | number
  description: string
  iteration?: number
  filter?: string
  onFilterChange?: (value: string | number) => void
  filterOptions?: MultiSelectOptionProps[]
}

const DashboardCard = ({ icon, value, description, iteration = 0, onFilterChange, filter, filterOptions }: DashboardCardProps) => {
  const iterationColor = {
    background: `linear-gradient(60deg, ${colors[iteration][0]}, ${colors[iteration][1]})`,
    boxShadow: `0 4px 20px 0 rgba(43,43,43,.14), 0 7px 10px -5px rgba(${colors[iteration][2]},.4)`,
  }

  return (
    <Card position="relative" h="7.5rem" w="100%" overflow="visible" marginTop={25} display="flex" justifyContent="center" alignItems="center">
      <SimpleGrid spacing={2} w="100%">
        <GridItem>
          <SimpleGrid spacing={2}>
            <GridItem>
              <Center fontSize="2xl" position="absolute" borderRadius="lg" left="2rem" top="-1rem" w="3.375rem" h="3.375rem" {...iterationColor}>
                <ChakraSpan color="white">{icon}</ChakraSpan>
              </Center>
            </GridItem>
            <GridItem textAlign="center">
              <Heading>{value}</Heading>
            </GridItem>
            {filterOptions && (
              <GridItem>
                <Center position="absolute" borderRadius="lg" right=".5rem" top="-1.4rem" w="8rem" h="3.375rem">
                  <MultiSelect
                    name={`filter-${description}`}
                    label=""
                    options={filterOptions}
                    isMulti={false}
                    border={0}
                    value={filter}
                    onChange={(event) => {
                      onFilterChange(event.target?.value)
                    }}
                  />
                </Center>
              </GridItem>
            )}
          </SimpleGrid>
        </GridItem>
        <GridItem w="100%">
          <Divider />
        </GridItem>
        <GridItem>
          <Center color="gray.500" h="2.5rem" textAlign="center" w="100%">
            <Text variant="text-14-reg">{description}</Text>
          </Center>
        </GridItem>
      </SimpleGrid>
    </Card>
  )
}

export default DashboardCard
