import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useState } from 'react'
import StoreApi from 'src/api/store'
import { DataTextField } from 'src/components/Form/DataTextField'
import { FormCard } from 'src/components/Layout/FormCard'
import AppContext from 'src/contexts/AppContext'
import { formatDateToString } from 'src/helpers/stringHelper'
import { Types } from 'src/reducers/AppReducer'
import { IStore } from 'src/types/User'
import { IUserActivity } from 'src/types/UserActivity'

interface StoreActivityProps {
  store: IStore
}

const StoreActivity = ({ store }: StoreActivityProps) => {
  const { dispatch } = React.useContext(AppContext)
  const [activities, setActivities] = useState<IUserActivity[]>([])

  React.useEffect(() => {
    if (store._id) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      StoreApi.getActivities(store._id as string, 20)
        .then((res) => {
          setActivities(
            res.data.map((activity: IUserActivity) => {
              let changes = []

              try {
                const changesObjects = activity.changes?.split(' -> ')
                const changeKeys = activity.changes?.split(':').slice(0)[0]
                const oldValues = JSON.parse(changesObjects[0].split(':').splice(1).join(':'))
                const newValues = JSON.parse(changesObjects[1])

                changes = Object.keys(newValues).map((key) => {
                  return {
                    key: changeKeys,
                    oldValue: JSON.stringify(oldValues[key], null, 2),
                    newValue: JSON.stringify(newValues[key], null, 2),
                  }
                })
              } catch (error) {
                console.log(error)
              }
              return {
                ...activity,
                createdAt: formatDateToString(activity.createdAt, {
                  month: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
                descriptionList: activity.description.split('.'),
                changesList: changes,
              }
            })
          )
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }
    // eslint-disable-next-line
  }, [dispatch, store._id])

  return (
    <FormCard title="User Activity">
      <SimpleGrid columns={1} spacing={4}>
        {activities.map((activity) => (
          <GridItem key={activity._id} borderBottom="1px solid" borderColor="gray.300" pb="4">
            <SimpleGrid columns={4} spacing={4}>
              <GridItem>
                <DataTextField title="Date">{(activity.createdAt as string) || '-'}</DataTextField>
              </GridItem>
              <GridItem>
                <DataTextField title="Action">{activity.action || '-'}</DataTextField>
              </GridItem>
              <GridItem>
                <DataTextField title="Entity">{activity.entity || '-'}</DataTextField>
              </GridItem>
              <GridItem>
                <DataTextField title="Details">{activity.description}</DataTextField>
              </GridItem>
              <GridItem colSpan={4}>
                {activity.changesList?.map((change) => (
                  <SimpleGrid columns={3} key={change.key} spacing={4}>
                    <GridItem>
                      <DataTextField title="Key">{change.key}</DataTextField>
                    </GridItem>
                    <GridItem>
                      <DataTextField title="Old Value">{change.oldValue}</DataTextField>
                    </GridItem>
                    <GridItem>
                      <DataTextField title="New Value">{change.newValue}</DataTextField>
                    </GridItem>
                  </SimpleGrid>
                ))}
              </GridItem>
            </SimpleGrid>
          </GridItem>
        ))}
      </SimpleGrid>
    </FormCard>
  )
}

export default StoreActivity
