import IUser, { IStore, storeInitialValue, userInitialValue } from './User'

export interface IReview {
  id?: string
  user: IUser
  business?: IStore
  grocer?: IStore
  rate: number
  comments: string
  photos?: string[]
  orderCode: string
  isReported: boolean
  reportReason: string
  author?: IUser
  createdAt?: Date
}

export const reviewInitialValue: IReview = {
  user: userInitialValue,
  business: storeInitialValue,
  rate: 0,
  comments: '',
  photos: [],
  orderCode: '',
  isReported: true,
  reportReason: '',
}

export default IReview
