import { validateVideoUrl } from 'src/helpers/stringHelper'
import * as Yup from 'yup'

export default Yup.object().shape({
  commissionPercentage: Yup.number().min(0).required('Commission Percentage is required'),
  statementEmails: Yup.array(Yup.string().email().required('Statement Email is required')),
  zeroCommissionTrialEndsAt: Yup.date().nullable().optional(),
  businessVideo: Yup.string().url().optional().nullable().test('is-valid', 'Please, use videos from Youtube or Vimeo', (value) => {
    if (!value) return true
    return validateVideoUrl(value)
  })
})
