export interface IImageSuggestion {
  _id?: string
  url: string
  isActive: boolean

  [key: string]: any
}

export const imageSuggestionInitialValue: IImageSuggestion = {
  url: '',
  isActive: false,
}
