import { Image } from '@chakra-ui/react'
import { TableColumn } from 'src/components/CustomDataTable'
import { IImageSuggestion } from 'src/types/ImageSuggestion'

const tableColumns: TableColumn<IImageSuggestion>[] = [
  {
    label: 'Image',
    name: 'url',
    options: {
      customRender: (values) => <Image src={values.url ? values.url : 'https://via.placeholder.com/150'} alt={values.name} style={{ width: '50px', height: '50px' }} />
    }
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
