import { Link, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import ReviewApi from 'src/api/review'
import { AlertSuccess } from 'src/components/Alert'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState, TableColumn } from 'src/components/CustomDataTable'
import { FormCard } from 'src/components/Layout/FormCard'
import { ReviewDetailsModal } from 'src/components/Overlay/ReviewDetailsModal'
import AppContext from 'src/contexts/AppContext'
import { formatDateToString } from 'src/helpers/stringHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import IReview from 'src/types/Review'
import { IStore } from 'src/types/User'

interface StoreReviewsProps {
  store: IStore
}

const tableColumns: TableColumn<IReview>[] = [
  {
    label: 'Author',
    name: 'author',
    options: {
      customRender: (review) => {
        return review["author._id"] ? (
          <Link href={review["author._id"]}>{`${review["author.firstName"]} ${review["author.lastName"]}`}</Link>
        ) : (
          "-"
        )
      },
    }
  },
  {
    label: 'Rating',
    name: 'rate',
  },
  {
    label: 'Replied By Store',
    name: "reply._id",
    isBoolean: true
  },
  {
    label: "Reported",
    name: "isReported",
    isBoolean: true
  },
  {
    label: 'Created At',
    name: 'createdAt',
    options: {
      customRender: (review) => {
        return formatDateToString(review.createdAt)
      },
    }
  },
]

const StoreReviews = ({ store }: StoreReviewsProps) => {
  const { dispatch } = React.useContext(AppContext)
  const [reviews, setReviews] = useState<PaginationResponse<IReview>>({ data: [], count: 0 })
  const [reviewSelected, setReviewSelected] = useState<IReview | null>(null)
  const reviewDisclosure = useDisclosure()
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  React.useEffect(() => {
    if (store._id) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      ReviewApi.getByBusiness(store._id as string)
        .then((res) => setReviews(res))
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }
    // eslint-disable-next-line
  }, [dispatch, store._id])

  const handleRowClick = (rowIndex: number) => {
    setReviewSelected(reviews.data[rowIndex])
    reviewDisclosure.onOpen()
  }

  const handleDeleteReview = (reviewId: string) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewApi.destroy(reviewId)
      .then((res) => {
        if (res.success) {
          setReviews((prev) => ({
            ...prev,
            data: prev.data.filter((review) => review.id !== reviewId),
          }))

          AlertSuccess('Review deleted successfully')
          reviewDisclosure.onClose()
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({
        type: Types.Loading, payload: { loading: false }
      }))
  }

  return (
    <FormCard title="Store Reviews">
      <CustomDataTable
        serverSidePagination
        onRowClick={handleRowClick}
        data={reviews}
        tableColumns={tableColumns}
        onTableChange={onTableChange}
        tableState={tableState}
      />

      <ReviewDetailsModal
        review={reviewSelected}
        onClose={() => {
          setReviewSelected(null)
          reviewDisclosure.onClose()
        }}
        onDelete={handleDeleteReview}
        isOpen={reviewDisclosure.isOpen}
      />
    </FormCard>
  )
}

export default StoreReviews
