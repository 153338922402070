import * as Yup from 'yup'

export default Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  isForSignup: Yup.boolean().required('Is For Signup is required'),
  signupStep: Yup.number().when("isForSignup", {
    is: true,
    then: (schema) => schema.required("Signup Step is required")
  })
})
