import { TableColumn } from 'src/components/CustomDataTable'
import { IBusinessFaq } from 'src/types/BusinessFaq'

const tableColumns: TableColumn<IBusinessFaq>[] = [
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Order',
    name: 'order',
  },
  {
    label: 'Is For Signup',
    name: 'isForSignup',
    isBoolean: true,
  },
  {
    label: 'Signup Step',
    name: 'signupStep',
  },
]

export default tableColumns
