import { Flex } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StoreApi from 'src/api/store'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { formatDateToInput } from 'src/helpers/stringHelper'
import { Types } from 'src/reducers/AppReducer'
import IProduct from 'src/types/Product'
import { IStore, storeInitialValue } from 'src/types/User'
import StoreForm from './StoreForm'
import validationSchema from './validationSchema'

const ViewStore = () => {
  const pageName = 'View Store'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const [store, setStore] = React.useState<IStore>(storeInitialValue)

  const onSubmit = (data: IStore) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    let storeObj: Partial<IStore> = { commissionPercentage: data.commissionPercentage, statementEmails: data.statementEmails }
    if (data.businessCoverFile) storeObj.businessCoverFile = data.businessCoverFile
    if (data.businessLogoFile) storeObj.businessLogoFile = data.businessLogoFile
    if (data.businessVideo !== undefined) storeObj.businessVideo = data.businessVideo
    if (data.socialMedias) storeObj.socialMedias = data.socialMedias
    if (data.zeroCommissionTrialEndsAt !== undefined) {

      if (data.zeroCommissionTrialEndsAt !== null && data.zeroCommissionTrialEndsAt !== "") {
        storeObj.zeroCommissionTrialEndsAt = new Date(data.zeroCommissionTrialEndsAt)
      } else {
        storeObj.zeroCommissionTrialEndsAt = null
      }
    }

    StoreApi.updateSettings(store?._id as string, storeObj)
      .then(() => {
        AlertSuccess('Store saved successfully!')
        fetchStore()
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }

  const formik = useFormik({
    initialValues: storeInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues } = formik

  const fetchStore = React.useCallback(async () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    try {
      let [storeData, repeatedCustomers] = await Promise.all([StoreApi.getById(params.id as string), StoreApi.getRepeatedCustomers(params.id as string)])

      if (repeatedCustomers?.length > 0) {
        storeData.products = storeData.products.map((product: IProduct) => {
          const repeatedCustomer = repeatedCustomers.find((customer) => customer.product._id === product._id)
          return { ...product, ...repeatedCustomer, product: undefined }
        })
      }

      if (storeData) {
        storeData.zeroCommissionTrialEndsAt = storeData.zeroCommissionTrialEndsAt ? formatDateToInput(storeData.zeroCommissionTrialEndsAt) : null
        setStore(storeData)
        setValues(storeData)
      }
    } catch (error) {
      AlertError('Error fetching store')
    } finally {
      dispatch({ type: Types.Loading, payload: { loading: false } })
    }
  }, [dispatch, params.id, setValues])

  React.useEffect(() => {
    fetchStore()
    // eslint-disable-next-line
  }, [dispatch, params.id])

  const handleChangeStatus = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.changeStatus([params.id as string])
      .then(() => { })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  const handleResetPassword = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.triggerResetPassword(params.id as string)
      .then((result) => {
        if (result.success) AlertSuccess('Password reset email sent!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  const handleApprove = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.approve(params.id as string)
      .then(() => {
        AlertSuccess('Store approved successfully')
        navigate('/app/stores')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleChangeVisibility = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.changeVisibility([params.id as string])
      .then(() => {
        setStore((prev) => ({
          ...prev,
          isVisible: !prev.isVisible,
        }))
        setValues((prev) => ({
          ...prev,
          isVisible: !prev.isVisible,
        }))
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack={true}
          buttons={
            <Flex gap="2">
              <PrimaryButton type="submit">Save</PrimaryButton>
              <PrimaryButton variant="outline" onClick={handleChangeVisibility}>
                {store?.isVisible ? 'Hide' : 'Show'}
              </PrimaryButton>
              {store?.approved ? (
                <>
                  <PrimaryButton variant="outline" onClick={handleChangeStatus}>
                    {store?.active ? 'Deactivate' : 'Activate'}
                  </PrimaryButton>
                  <PrimaryButton variant="outline" onClick={handleResetPassword}>
                    Reset Password
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  colorScheme={store?.products?.length > 0 ? 'green' : 'red'}
                  onClick={handleApprove}
                  isDisabled={store?.products?.length === 0}
                >
                  {store?.products?.length > 0 ? 'Approve' : 'Store has no products'}
                </PrimaryButton>
              )}
            </Flex>
          }
        />
        <StoreForm store={store} setStore={setStore} formik={formik} />
      </form>
    </Page>
  )
}

export default ViewStore
