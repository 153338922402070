import { PaginationResponse } from 'src/types/Pagination'
import IReview from 'src/types/Review'
import { api } from '.'

type IReviewApi = {
  get(): Promise<IReview[]>
  getByBusiness(businessId: string): Promise<PaginationResponse<IReview>>
  destroy(id: string): Promise<{ success: boolean }>
}

const ReviewApi = (): IReviewApi => {
  const reviewApi = '/admin/review'

  const get = (): Promise<IReview[]> => api.get(reviewApi)
  const getByBusiness = (businessId: string): Promise<PaginationResponse<IReview>> => api.get(`${reviewApi}/business/${businessId}`)
  const destroy = (id: string): Promise<{ success: boolean }> => api.delete(`${reviewApi}/${id}`)

  return {
    get,
    getByBusiness,
    destroy
  }
}

export default ReviewApi()
