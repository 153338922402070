import { TableColumn } from 'src/components/CustomDataTable'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IVoucher } from 'src/types/Voucher'

const tableColumns: TableColumn<IVoucher>[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'Amount',
    options: {
      customRender: (voucher) => `$${voucher.amount}`,
    },
  },
  {
    label: 'Used At',
    name: 'grocerUsed',
    options: {
      customRender: (voucher: any) => {
        return voucher['grocerUsed.businessName'] ? `${voucher['grocerUsed.businessName']} - ${(voucher['grocerUsed.address.suburb'])}` : "-"
      }
    },
  },
  {
    label: 'Used On',
    name: 'usedOn',
    options: {
      customRender: (voucher) => voucher.usedOn ? formatDateToString(voucher.usedOn) : "-",
    },
  },
  {
    label: 'Is Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
