import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface StoreDetailsCardProps {
  store: IStore
}

export function StoreDetailsCard({ store }: StoreDetailsCardProps) {
  return (
    <FormCard title="Store Details">
      <SimpleGrid columns={1} spacing={2}>
        <GridItem>
          <DataTextField title="Store ID">{store._id || '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Store Code">{store.code || '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Legal Business Name">{store.legalBusinessName || '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Business Name">{store.businessName}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="ABN">{store.abn}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Business Type">{store.businessType}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Description">{store.businessDescription}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Favourites">{store.favoritesQty || '-'}</DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
