import { Flex, GridItem } from '@chakra-ui/react'
import IProduct from 'src/types/Product'
import { IStore } from 'src/types/User'
import { PrimaryButton } from '../Button/PrimaryButton'
import { FormCard } from './FormCard'
import { ProductCard } from './ProductCard'
import Toolbar from './Toolbar'

interface StoreProductsCardProps {
  store: IStore
  changeProductStatus?: (id: string) => void
  togglePriceCheck?: (id: string) => void
  onCreate: () => void
  onUpdatePicture: (product: IProduct) => void
}

export function StoreProductsCard({ store, changeProductStatus, togglePriceCheck, onCreate, onUpdatePicture }: StoreProductsCardProps) {
  return (
    <>
      <Toolbar title="Store Products" buttons={<PrimaryButton onClick={onCreate}>Create</PrimaryButton>} />
      <FormCard title="">
        <GridItem>
          <Flex wrap="wrap" gap={4}>
            {store?.products?.map((product: IProduct) => (
              <ProductCard key={product._id} product={product} changeProductStatus={changeProductStatus} togglePriceCheck={togglePriceCheck} onUpdatePicture={onUpdatePicture} />
            ))}
          </Flex>
        </GridItem>
      </FormCard>
    </>
  )
}
